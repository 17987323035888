import React from "react";

import "./src/base/styles/main.scss";
// eslint-disable-next-line import/no-unresolved
import "@splidejs/react-splide/css/core";
import { MatchMediaProvider } from "./src/base/context/matchMedia";

export const wrapRootElement = ({ element }) => {
  return <MatchMediaProvider>{element}</MatchMediaProvider>;
};
